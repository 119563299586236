.loader-overlay {
    position: fixed;
    height: 100vh;
    width: 100%;
    left: 0;
    top: 0;
    background-color: rgba(0,0,0,0.15);
    z-index: 1001;
}

.loader-container {
    position: absolute;
    left: 50%;
    top: 50%;
    text-align: center;
    transform: translate(-50%, -50%);
    max-width: 280px;
}

.loader-text {
    font-weight: bold;
    font-size: 18px;
    line-height: 150%;
    letter-spacing: 0.5px;
    color: #223263;
    margin-top: 10px;
}

@keyframes ldio-x3aj2sv26gh {
    0% { opacity: 1 }
    100% { opacity: 0 }
}
.ldio-x3aj2sv26gh div {
    left: 15px;
    top: 2.5px;
    position: absolute;
    animation: ldio-x3aj2sv26gh linear 1s infinite;
    background: #a7a7a7;
    width: 3px;
    height: 9px;
    border-radius: 2px / 2.38px;
    transform-origin: 2px 16.5px;
}.ldio-x3aj2sv26gh div:nth-child(1) {
     transform: rotate(0deg);
     animation-delay: -0.9166666666666666s;
     background: #a7a7a7;
 }.ldio-x3aj2sv26gh div:nth-child(2) {
      transform: rotate(30deg);
      animation-delay: -0.8333333333333334s;
      background: #a7a7a7;
  }.ldio-x3aj2sv26gh div:nth-child(3) {
       transform: rotate(60deg);
       animation-delay: -0.75s;
       background: #a7a7a7;
   }.ldio-x3aj2sv26gh div:nth-child(4) {
        transform: rotate(90deg);
        animation-delay: -0.6666666666666666s;
        background: #a7a7a7;
    }.ldio-x3aj2sv26gh div:nth-child(5) {
         transform: rotate(120deg);
         animation-delay: -0.5833333333333334s;
         background: #a7a7a7;
     }.ldio-x3aj2sv26gh div:nth-child(6) {
          transform: rotate(150deg);
          animation-delay: -0.5s;
          background: #a7a7a7;
      }.ldio-x3aj2sv26gh div:nth-child(7) {
           transform: rotate(180deg);
           animation-delay: -0.4166666666666667s;
           background: #a7a7a7;
       }.ldio-x3aj2sv26gh div:nth-child(8) {
            transform: rotate(210deg);
            animation-delay: -0.3333333333333333s;
            background: #a7a7a7;
        }.ldio-x3aj2sv26gh div:nth-child(9) {
             transform: rotate(240deg);
             animation-delay: -0.25s;
             background: #a7a7a7;
         }.ldio-x3aj2sv26gh div:nth-child(10) {
              transform: rotate(270deg);
              animation-delay: -0.16666666666666666s;
              background: #a7a7a7;
          }.ldio-x3aj2sv26gh div:nth-child(11) {
               transform: rotate(300deg);
               animation-delay: -0.08333333333333333s;
               background: #a7a7a7;
           }.ldio-x3aj2sv26gh div:nth-child(12) {
                transform: rotate(330deg);
                animation-delay: 0s;
                background: #a7a7a7;
            }
.loadingio-spinner-spinner-nm4xms9n0oq {
    width: 35px;
    height: 35px;
    display: inline-block;
    overflow: hidden;
    background: none;
}
.ldio-x3aj2sv26gh {
    width: 100%;
    height: 100%;
    position: relative;
    transform: translateZ(0) scale(1);
    backface-visibility: hidden;
    transform-origin: 0 0; /* see note above */
}
.ldio-x3aj2sv26gh div { box-sizing: content-box; }