.pagination {
    display: flex;
    padding: 20px 0;
}

.pagination > li {
    margin: 0 2.5px;
}

.pagination > li > a {
    background: #F1F1F1;
    width: 28px;
    height: 30px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    outline: none;
}

.pagination > li:hover > a {
    background: #eaeaea;
}

.pagination > li.active > a {
    background: #ffd200;
}

.pagination > li.previous {
    margin-right: 20px;
}

.pagination > li.next {
    margin-left: 20px;
}

.pagination > li.previous > a,
.pagination > li.next > a {
    width: 30px;
    height: 30px;
    border: 1px solid #EBF0FF;
    box-sizing: border-box;
    background: #EBF0FF;
}

.pagination > li.previous:hover > a,
.pagination > li.next:hover > a {
    background: #dde3f5;
}

.pagination > li.previous.disabled > a,
.pagination > li.next.disabled > a{
    background-color: white;
}