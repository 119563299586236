@font-face {
    font-family: 'Comfortaa';
    font-weight: 600;
    font-style: normal;
    src: url('./Comfortaa-SemiBold.ttf');
    src: local('Comfortaa SemiBold'), local('Comfortaa-SemiBold'),
    url('./Comfortaa-SemiBold.ttf') format('truetype');
    font-display: swap;
}

@font-face {
    font-family: 'Comfortaa';
    font-weight: normal;
    font-style: normal;
    src: url('./Comfortaa-Regular.ttf');
    src: local('Comfortaa Regular'), local('Comfortaa-Regular'),
    url('./Comfortaa-Regular.ttf') format('truetype');
    font-display: swap;
}

@font-face {
    font-family: 'Comfortaa';
    font-weight: bold;
    font-style: normal;
    src: url('./Comfortaa-Bold.ttf');
    src: local('Comfortaa Bold'), local('Comfortaa-Bold'),
    url('./Comfortaa-Bold.ttf') format('truetype');
    font-display: swap;
}

@font-face {
    font-family: 'Roboto';
    font-weight: normal;
    font-style: normal;
    src: url('./Roboto-Regular.ttf');
    src: local('Roboto Regular'), local('Roboto-Regular'),
    url('./Roboto-Regular.ttf') format('truetype');
    font-display: swap;
}

@font-face {
    font-family: 'Roboto';
    font-weight: bold;
    font-style: normal;
    src: url('./Roboto-Bold.ttf');
    src: local('Roboto Bold'), local('Roboto-Bold'),
    url('./Roboto-Bold.ttf') format('truetype');
    font-display: swap;
}